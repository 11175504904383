import React from "react"
import StyledAbout from "./style/about"
import { Link } from "gatsby"
import Button from "../common/Button"
import { StaticImage } from "gatsby-plugin-image"

const About = ({ data }) => {
  return (
    <StyledAbout>
      <div className="main-wrapper">
        <h2>{data.heading}</h2>

        <div className="description ">
          {data.rightDescription.map((d, i) => (
            <p key={i}>{d.description}</p>
          ))}
        </div>

        <div className="mt-4">
          <Link to="/about">
            <Button name="Know more..." />
          </Link>
        </div>
      </div>

      <StaticImage
        formats={["auto", "webp", "avif"]}
        placeholder="blurred"
        src="../../../static/assets/home/about.jpg"
        alt="people holding hands together"
        title="people holding hands together"
        className="rounded-3xl object-contain"
      />
    </StyledAbout>
  )
}

export default About
