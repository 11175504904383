import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"

const BannerServices = () => {
  const data = [
    {
      id: 1,
      image: "/assets/BannerServices/UIuxdesign.svg",
      name: "UI/UX DESIGN",
      link: "/ui-ux-design-service/",
    },
    {
      id: 2,
      image: "/assets/BannerServices/website.svg",
      name: "WEB DEVELOPMENT",
      link: "/web-developement-service/",
    },
    {
      id: 3,
      image: "/assets/BannerServices/mobileapp.svg",
      name: "MOBILE APP DEVELOPMENT",
      link: "/mobile-development-service/",
    },
    {
      id: 4,
      image: "/assets/BannerServices/Testing.svg",
      name: "TESTING",
      link: "/functional-testing-service/",
    },
    {
      id: 5,
      image: "/assets/BannerServices/Digitalmarketing.svg",
      name: "DIGITAL MARKETING",
      link: "/searchEngine-Optimization-services/",
    },
  ]
  return (
    <div className="md:flex justify-between 2xl:px-[160px] sm:px-[50px] px-[10px]   md:space-x-10 space-y-5 mb-10">
      <div className="grid w-[25%] 2xl:mt-16 sm:mt-16 mt-10">
        <h2 className="sm:justify-self-end">SERVICES</h2>
      </div>

      <div className="flex text-center md:space-x-8 space-x-3">
        {/* {data.map((s, idx) => ( */}
        <div className="">
          <Link href="/ui-ux-design-service/">
            <StaticImage
              formats={["auto", "webp", "avif"]}
              placeholder="blurred"
              src="../../../static/assets/BannerServices/UIuxdesign.svg"
              alt="UI/UX DESIGN"
              title="UI/UX DESIGN"
              className="hover:scale-95 duration-500 overflow-hidden w-full h-full"
            />

            <h2 className="xl:text-[17px] sm:text-[10px] text-[8px] ">
              UI/UX DESIGN
            </h2>
          </Link>
        </div>

        <div className="">
          <Link href="/web-developement-service/">
            <StaticImage
              formats={["auto", "webp", "avif"]}
              placeholder="blurred"
              src="../../../static/assets/BannerServices/website.svg"
              alt="UI/UX DESIGN"
              title="UI/UX DESIGN"
              className="hover:scale-95 duration-500 overflow-hidden w-full h-full"
            />

            <h2 className="xl:text-[17px] sm:text-[10px] text-[8px] ">
              WEB DEVELOPMENT
            </h2>
          </Link>
        </div>

        <div className="">
          <Link href="/mobile-development-service/">
            <StaticImage
              formats={["auto", "webp", "avif"]}
              placeholder="blurred"
              src="../../../static/assets/BannerServices/mobileapp.svg"
              alt="UI/UX DESIGN"
              title="UI/UX DESIGN"
              className="hover:scale-95 duration-500 overflow-hidden w-full h-full"
            />
            <h2 className="xl:text-[17px] sm:text-[10px] text-[8px] ">
              MOBILE APP DEVELOPMENT
            </h2>
          </Link>
        </div>

        <div className="">
          <Link href="/ui-ux-design-service/">
            <StaticImage
              formats={["auto", "webp", "avif"]}
              placeholder="blurred"
              src="../../../static/assets/BannerServices/Testing.svg"
              alt="UI/UX DESIGN"
              title="UI/UX DESIGN"
              className="hover:scale-95 duration-500 overflow-hidden w-full h-full"
            />

            <h2 className="xl:text-[17px] sm:text-[10px] text-[8px] ">
              TESTING
            </h2>
          </Link>
        </div>

        <div className="">
          <Link href="/searchEngine-Optimization-services/">
            <StaticImage
              formats={["auto", "webp", "avif"]}
              placeholder="blurred"
              src="../../../static/assets/BannerServices/Digitalmarketing.svg"
              alt="UI/UX DESIGN"
              title="UI/UX DESIGN"
              className="hover:scale-95 duration-500 overflow-hidden w-full h-full"
            />

            <h2 className="xl:text-[17px] sm:text-[10px] text-[8px] ">
              DIGITAL MARKETING
            </h2>
          </Link>
        </div>
        {/* ))} */}
      </div>
    </div>
  )
}

export default BannerServices
