import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Banner from "../components/home/Banner"
import Industries from "../components/home/Industries"
import History from "../components/home/History"
import bannerData from "../content/home/banner.json"
import insustriesData from "../content/home/industries.json"
import historyData from "../content/home/history.json"
import OurProcess from "../components/home/OurProcess"
import ourProcessData from "../content/home/our-process.json"
import OurClients from "../components/home/OurClients"
import OurClientsData from "../content/home/our-clients.json"
import Testimonials from "../components/home/Testimonials"
import testimonialsData from "../content/home/testimonials.json"
import HomeServices from "../components/home/HomeServices"
import HomeTestingServices from "../components/home/HomeTestingServices"
import VisionMission from "../components/about/VisionMission"

import aboutData from "../content/home/about.json"

import visionMissionData from "../content/about/vision-mission.json"
import BannerServices from "../components/home/BannerServices"
import About from "../components/home/About"
import CaseStudies from "../components/home/CaseStudies"
import PopupForm from "../components/common/PopupForm"
import MetaTags from "react-meta-tags"
import SEO from "../components/seo"
import Location from "../components/home/Location"

export const pageQuery = graphql`
  query HomeQuery {
    services: allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___date] }
      filter: { frontmatter: { template: { eq: "service-details" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            slug
            title
            homeSection {
              bgImage {
                publicURL
              }
              shortDescription
              icon {
                publicURL
              }
            }
          }
        }
      }
    }
    testingServices: allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___date] }
      filter: { frontmatter: { template: { eq: "testing-service-details" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            slug
            title
            homeSection {
              bgImage {
                publicURL
              }
              shortDescription
              icon {
                publicURL
              }
            }
          }
        }
      }
    }
  }
`

const HomePage = ({ data }) => {
  const { services, testingServices } = data

  const keywords = [
    "best website development company",
    "website design and development services",
    "best digital marketing services",
    "software testing services",
    "mobile app development company",
  ]

  return (
    <Layout>
      <SEO
        title="Website Design & Development | Digital Marketing | Mobile App."
        description="A leading Website Design & Development, Testing Service, Digital Marketing & company. Additionally, We offer App development, E- Commerce, Logo designing, Web Hosting."
        keywords={keywords}
      />
      <div
        className="sm:bg-cover bg-left bg-no-repeat pt-8 relative"
        style={{
          backgroundImage: `url(${bannerData.image})`,
        }}
      >
        <Banner data={bannerData} />
        <BannerServices />
      </div>

      <About data={aboutData} />

      {/* <VisionMission data={visionMissionData} /> */}
      {/* <Industries data={insustriesData} /> */}
      <HomeServices data={services} />
      {/* <HomeTestingServices data={testingServices} /> */}
      <div className="bg-white 2xl:mx-[160px] sm:mx-[50px] mx-[10px] md:p-8 p-2 rounded-2xl md:mb-6 mb-2">
        <History data={historyData} />
        <CaseStudies />
        <OurClients data={OurClientsData} />
        <Testimonials data={testimonialsData} />
      </div>
      <PopupForm />

      {/* <Location /> */}

      {/* <OurProcess data={ourProcessData} /> */}
    </Layout>
  )
}

export default HomePage
